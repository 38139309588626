import React, { FunctionComponent } from 'react'
import Link, { LinkProps } from 'next/link'
import { motion, Variants } from 'framer-motion'

import styled from '@emotion/styled'
import { Flex } from '@mindfulchefuk/design-system/layout/Flex'
import { Text } from '@mindfulchefuk/design-system/Text/Text'
import { Icon } from '@mindfulchefuk/design-system/Icon/Icon'

const MotionFlex = motion(Flex)
const MotionIconWrapper = styled(motion.span)`
  display: inline-flex;
`

const styledArrowContainerVariants: Variants = {
  hover: {
    x: [0, -10, 0, -10, 0],
    transition: { duration: 0.6, ease: 'easeOut' },
  },
}

interface Props {
  href: LinkProps['href']
  'data-testid'?: string
  onClick?: () => void
}

export const BackLink: FunctionComponent<Props> = ({
  children,
  href,
  'data-testid': testId,
  onClick,
}) => (
  <Link href={href} passHref onClick={() => onClick()} legacyBehavior={false}>
    <MotionFlex
      as="a"
      color="aubergine"
      align="center"
      initial={false}
      whileHover="hover"
    >
      <MotionIconWrapper variants={styledArrowContainerVariants}>
        <Icon type="arrow-left" mr={10} size={20} />
      </MotionIconWrapper>

      <Text
        as="span"
        variant={{ base: 'body-sm-500-caps', md: 'body-lg-500-caps' }}
        color="aubergine"
        data-testid={testId}
      >
        {children}
      </Text>
    </MotionFlex>
  </Link>
)
