export const space = {
  [-384]: '-384px',
  [-320]: '-320px',
  [-288]: '-288px',
  [-256]: '-256px',
  [-240]: '-240px',
  [-224]: '-224px',
  [-208]: '-208px',
  [-192]: '-192px',
  [-176]: '-176px',
  [-160]: '-160px',
  [-144]: '-144px',
  [-128]: '-128px',
  [-112]: '-112px',
  [-96]: '-96px',
  [-80]: '-80px',
  [-72]: '-72px',
  [-64]: '-64px',
  [-56]: '-56px',
  [-48]: '-48px',
  [-44]: '-44px',
  [-40]: '-40px',
  [-36]: '-36px',
  [-32]: '-32px',
  [-28]: '-28px',
  [-24]: '-24px',
  [-20]: '-20px',
  [-16]: '-16px',
  [-14]: '-14px',
  [-12]: '-12px',
  [-10]: '-10px',
  [-8]: '-8px',
  [-6]: '-6px',
  [-4]: '-4px',
  [-2]: '-2px',
  [-1]: '-1px',
  0: '0px',
  1: '1px',
  2: '2px',
  4: '4px',
  6: '6px',
  8: '8px',
  10: '10px',
  12: '12px',
  14: '14px',
  16: '16px',
  20: '20px',
  24: '24px',
  28: '28px',
  32: '32px',
  36: '36px',
  40: '40px',
  44: '44px',
  48: '48px',
  56: '56px',
  64: '64px',
  72: '72px',
  80: '80px',
  88: '88px',
  96: '96px',
  112: '112px',
  128: '128px',
  144: '144px',
  160: '160px',
  176: '176px',
  192: '192px',
  208: '208px',
  224: '224px',
  240: '240px',
  256: '256px',
  288: '288px',
  320: '320px',
  384: '384px',
  448: '448px',
  512: '512px',
  640: '640px',
  768: '768px',
  896: '896px',
  1024: '1024px',
}

export type Space = typeof space
export type SpaceToken = keyof Space | string
