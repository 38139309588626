import { RecipesRecommendation } from '@mindfulchefuk/api-client'
import { PortionCount } from '@mindfulchefuk/config/portionCountConfig'
import {
  foodGroup as FoodGroup,
  mealPlan as MealPlan,
} from '@mindfulchefuk/config/mealPlanConfig'
import {
  APIRecipeRecommendation,
  RecipeRecommendation,
} from '@mindfulchefuk/features/Recipes/interfaces'

export type GetRecipeRecommendationParams = {
  portionCount: PortionCount
  date: string
  excludedFoodGroups: FoodGroup[]
  mealPlan: MealPlan
  customerId?: string
}

export const getRecipeRecommendations = async ({
  portionCount,
  date,
  excludedFoodGroups,
  mealPlan = 'Balanced',
  customerId,
}: GetRecipeRecommendationParams): Promise<RecipeRecommendation[]> => {
  const recommendationResult = await RecipesRecommendation.select([
    'available_from',
    'recipe_id',
    'sort_order',
    'price',
    'portions',
    'recommended',
  ])
    .where({
      portion_count_per_meal: portionCount,
      delivery_date: date,
      excluded_food_groups: excludedFoodGroups,
      meal_plan: mealPlan,
      customer_id: customerId,
      all_recipes: true,
    })
    .extraParams({ pagination_links: false })
    .all()

  const recommendationData =
    recommendationResult.data as APIRecipeRecommendation[]

  return recommendationData.map((arrItem) => {
    const {
      portions,
      recipe_id: recipeId,
      price: priceString,
      sort_order: sortOrder,
      recommended,
    } = arrItem

    const price = parseFloat(priceString)
    const pricePerPerson = price / portions

    return {
      portions,
      price,
      pricePerPerson,
      recipeId,
      sortOrder,
      recommended,
    }
  })
}
